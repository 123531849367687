/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/models/Timi_RPM_Outfit01.glb 
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function TimiModel3(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF(
    './models/Timi_RPM_Outfit01.glb'
  )
  // const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="RPM004" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="Armature005" rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <primitive object={nodes.Hips} />
            <skinnedMesh
              castShadow
              name="RPM_EYE003"
              geometry={nodes.RPM_EYE003.geometry}
              material={materials.Mt_Eye_RPM}
              skeleton={nodes.RPM_EYE003.skeleton}
            />
            <skinnedMesh
              castShadow
              name="RPM_outfit001"
              geometry={nodes.RPM_outfit001.geometry}
              material={materials.Outfit}
              skeleton={nodes.RPM_outfit001.skeleton}
            />
            <skinnedMesh
              castShadow
              name="Wolf3D_Hair001"
              geometry={nodes.Wolf3D_Hair001.geometry}
              material={materials['Wolf3D_Hair.001']}
              skeleton={nodes.Wolf3D_Hair001.skeleton}
            />
            <skinnedMesh
              castShadow
              name="Wolf3D_Hair003"
              geometry={nodes.Wolf3D_Hair003.geometry}
              material={materials['Wolf3D_Hair.002']}
              skeleton={nodes.Wolf3D_Hair003.skeleton}
            />
            <skinnedMesh
              castShadow
              name="Head002"
              geometry={nodes.Head002.geometry}
              material={materials.Head}
              skeleton={nodes.Head002.skeleton}
            />
            <skinnedMesh
              castShadow
              name="Teeth002"
              geometry={nodes.Teeth002.geometry}
              material={materials.Teeth}
              skeleton={nodes.Teeth002.skeleton}
            />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('./models/Timi_RPM_Outfit01.glb')
