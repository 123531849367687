import { Stats, OrbitControls, Circle } from '@react-three/drei'
import { Canvas, useThree } from '@react-three/fiber'
import { useLoader } from '@react-three/fiber'
import { CubeTextureLoader } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Experience from './components/Experience'
import Interface from './components/Interface'

// export default function App() {
//   const gltf = useLoader(GLTFLoader, './models/Timi_RPM_Outfit01.glb')
//   return (
//     <>
//       <Canvas camera={{ position: [1, 1, 2] }} shadows={false}>
//         <directionalLight position={[3.3, 1.0, 4.4]} castShadow={false} />
//         <primitive object={gltf.scene} position={[0, 0, 0]} children-0-castShadow={false} />
//         {/* <Circle args={[10]} rotation-x={-Math.PI / 2} receiveShadow={true}>
//           <meshStandardMaterial />
//         </Circle> */}
//         <OrbitControls target={[0, 1, 0]} />
//         {/* <axesHelper args={[5]} /> */}
//         <SkyBox />
//         <Stats />
//       </Canvas>
//     </>
//   )
// }

export default function App() {
  return (
    <>
      <Canvas camera={{ fov: 40 }} shadows>
        <Experience />
        <SkyBox />
      </Canvas>
      {/* <Interface /> */}
    </>
  )
}

function SkyBox() {
  const { scene } = useThree()
  const loader = new CubeTextureLoader()
  // The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
  const texture = loader.load([
    '/1.jpg',
    '/2.jpg',
    '/3.jpg',
    '/4.jpg',
    '/5.jpg',
    '/6.jpg'
  ])

  // Set the scene background property to the resulting texture.
  scene.background = texture
  return null
}
