/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/models/Timi_RPM.glb 
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function TimiModel0(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./models/Timi_RPM.glb')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="RPM" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="Armature" rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <primitive object={nodes.Hips} />
            <skinnedMesh
              name="RPM_Body"
              geometry={nodes.RPM_Body.geometry}
              material={materials.Mt_Body_RPM}
              skeleton={nodes.RPM_Body.skeleton}
            />
            <skinnedMesh
              name="RPM_EYE"
              geometry={nodes.RPM_EYE.geometry}
              material={materials.Mt_Eye_RPM}
              skeleton={nodes.RPM_EYE.skeleton}
            />
            <skinnedMesh
              name="RPM_Hair"
              geometry={nodes.RPM_Hair.geometry}
              material={materials.MT_Hair_RPM}
              skeleton={nodes.RPM_Hair.skeleton}
            />
            <skinnedMesh
              name="RPM_Outfit_Bottom"
              geometry={nodes.RPM_Outfit_Bottom.geometry}
              material={materials.Wolf3D_Outfit_Bottom}
              skeleton={nodes.RPM_Outfit_Bottom.skeleton}
            />
            <skinnedMesh
              name="RPM_Outfit_Footwear"
              geometry={nodes.RPM_Outfit_Footwear.geometry}
              material={materials.Wolf3D_Outfit_Footwear}
              skeleton={nodes.RPM_Outfit_Footwear.skeleton}
            />
            <skinnedMesh
              name="RPM_Outfit_Top"
              geometry={nodes.RPM_Outfit_Top.geometry}
              material={materials.Wolf3D_Outfit_Top}
              skeleton={nodes.RPM_Outfit_Top.skeleton}
            />
            <skinnedMesh
              name="RPM_Head"
              geometry={nodes.RPM_Head.geometry}
              material={materials.Mt_Head_RPM}
              skeleton={nodes.RPM_Head.skeleton}
            />
            <skinnedMesh
              name="RPM_Teeth"
              geometry={nodes.RPM_Teeth.geometry}
              material={materials.lambert9}
              skeleton={nodes.RPM_Teeth.skeleton}
            />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('./models/Timi_RPM.glb')
