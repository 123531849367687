/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/models/Timi_RPM_Outfit02.glb 
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

const TimiModel2 = (props) => {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF(
    './models/Timi_RPM_Outfit02.glb'
  )
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          castShadow
          name="Outfit02_Part_1"
          geometry={nodes.Outfit02_Part_1.geometry}
          material={materials.Outfit_02}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <mesh
          castShadow
          name="Outfit02_Part_2"
          geometry={nodes.Outfit02_Part_2.geometry}
          material={materials.Outfit_02}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <mesh
          castShadow
          name="RPM_EYE003"
          geometry={nodes.RPM_EYE003.geometry}
          material={materials.Mt_Eye_RPM}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <mesh
          castShadow
          name="Wolf3D_Hair001"
          geometry={nodes.Wolf3D_Hair001.geometry}
          material={materials['Wolf3D_Hair.001']}
        />
        <mesh
          castShadow
          name="Wolf3D_Hair003"
          geometry={nodes.Wolf3D_Hair003.geometry}
          material={materials['Wolf3D_Hair.002']}
        />
        <mesh
          castShadow
          name="Head002"
          geometry={nodes.Head002.geometry}
          material={materials.Head}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <mesh
          castShadow
          name="Teeth002"
          geometry={nodes.Teeth002.geometry}
          material={materials.Teeth}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
      </group>
    </group>
  )
}

export default TimiModel2

useGLTF.preload('./models/Timi_RPM_Outfit02.glb')
