import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './styles.css'
import App from './App'
import { MantineProvider } from '@mantine/core'
import { CharacterAnimationsProvider } from './contexts/CharacterAnimations'

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        globalStyles: (_theme) => ({
          body: {
            width: '100vw',
            height: '100vh'
          },
          '#root': {
            width: '100%',
            height: '100%'
          }
        })
      }}>
      <CharacterAnimationsProvider>
        <App />
      </CharacterAnimationsProvider>
    </MantineProvider>
  </StrictMode>
)
