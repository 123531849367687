/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/models/Timi.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useEffect } from 'react'
import { useCharacterAnimations } from '../contexts/CharacterAnimations'

const TimiModel = (props) => {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./models//Timi.gltf')
  const { actions, names } = useAnimations(animations, group)
  const { setAnimations } = useCharacterAnimations()
  useEffect(() => {
    setAnimations(names)
  }, [names, setAnimations])

  useEffect(() => {
    actions[names[21]].reset().fadeIn(0.5).play()
    return () => {
      actions[names[21]].fadeOut(0.5)
    }
  }, [actions, names])

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Group" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="MotionSystem">
            <group name="FKIKSystem">
              <group
                name="FKIKParentConstraintSpine_M"
                position={[0.37, -4.559, -99.087]}
                rotation={[1.548, -0.001, -1.563]}
              />
            </group>
            <group name="FKSystem">
              <group
                name="FKFollowRoot"
                position={[0.37, -5.56, -99.156]}
                rotation={[0.055, -0.008, 0]}>
                <group name="FKOffsetRoot_M" rotation={[1.678, 0, -1.571]}>
                  <group
                    name="FKExtraRoot_M"
                    position={[-96.973, 0.407, -21.442]}
                    rotation={[3.141, 0.162, -1.563]}>
                    <primitive object={nodes.FKXRoot_M} />
                    <group
                      name="HipSwingerOffset_M"
                      position={[-20.306, 104.699, -4.666]}
                      rotation={[-2.98, 0, -1.563]}
                    />
                    <group
                      name="HipSwingerStabilizerTarget"
                      position={[-20.306, 104.699, -4.666]}
                      rotation={[-2.98, 0, -1.563]}
                    />
                    <group
                      name="HipSwingReverse"
                      position={[0.456, 109.918, -3.806]}
                      rotation={[-2.98, 0, -1.563]}>
                      <group
                        name="HipSwingReversePart1"
                        position={[-5.452, 0, 0]}
                        rotation={[0, -0.184, 0]}>
                        <group
                          name="HipSwingReverseRoot"
                          position={[-5.452, 0, 0]}
                          rotation={[0, -0.184, 0]}
                        />
                      </group>
                      <group
                        name="HipSwingReverseXPart1"
                        position={[-5.452, 0, 0]}
                        rotation={[0, -0.184, 0]}
                      />
                    </group>
                  </group>
                </group>
              </group>
            </group>
            <group name="IKSystem">
              <group name="IKHandle">
                <group name="IKHandleFollowMain">
                  <group
                    name="IKOffsetConstrainedSpine1_M"
                    position={[0.37, -5.56, -99.156]}
                    rotation={[0.055, -0.008, 0]}>
                    <group
                      name="IKhybridOffsetSpine1_M"
                      rotation={[1.678, 0, -1.571]}>
                      <group name="IKhybridFollowSpine1_M">
                        <group name="IKhybridExtraSpine1_M">
                          <group
                            name="IKhybridOffsetSpine2_M"
                            position={[11.692, 0, 0.269]}
                            rotation={[0, -0.085, 0]}>
                            <group name="IKhybridExtraSpine2_M">
                              <group
                                name="IKhybridOffsetSpine3_M"
                                position={[11.688, 0, 0.503]}
                                rotation={[0, -0.056, 0]}>
                                <group name="IKhybridExtraSpine3_M">
                                  <group
                                    name="IKOffsetSpine3_M"
                                    rotation={[0.001, -1.536, 1.572]}>
                                    <group name="IKExtraSpine3_M">
                                      <group
                                        name="IKSpine3LocalOrient1_M"
                                        position={[0.107, -0.295, 0.014]}
                                        rotation={[1.438, -0.006, -1.556]}>
                                        <group name="IKSpine3LocalOrient2_M" />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group
                        name="IKOffsetSpine1_M"
                        rotation={[-3.141, -1.464, -1.571]}>
                        <group name="IKExtraSpine1_M">
                          <group
                            name="IKSpine1LocalOrient1_M"
                            rotation={[1.678, 0, -1.571]}>
                            <group name="IKSpine1LocalOrient2_M" />
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group
                    name="IKOffsetSpine2_M"
                    position={[0.427, -4.034, -110.739]}
                    rotation={[0.05, -0.015, -0.003]}>
                    <group name="IKExtraSpine2_M">
                      <group
                        name="IKSpine2LocalOrient1_M"
                        position={[0.044, 0.271, 0.006]}
                        rotation={[1.678, 0, -1.571]}>
                        <group name="IKSpine2LocalOrient2_M" />
                      </group>
                    </group>
                  </group>
                  <group name="IKScalerRoot_M" />
                </group>
              </group>
              <group name="IKJoints">
                <group
                  name="IKParentConstraintRoot_M"
                  rotation={[-Math.PI / 2, 0, 0]}>
                  <primitive object={nodes.IKXOffsetRoot_M} />
                  <primitive object={nodes.IKfake0Spine_M} />
                </group>
              </group>
            </group>
            <group name="MainSystem" />
          </group>
        </group>
        <group name="Geometry" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="geo" />
        </group>
        <group
          name="C87_ShoesC59"
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <group name="Timi_UE_BaseTimi_UE_2parts" scale={0.01} />
        <group name="TiMi" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="Body1" />
        </group>
        <group name="Armature" scale={0.01}>
          <primitive object={nodes.Root_M} />
          <skinnedMesh
            name="C59_Boots"
            geometry={nodes.C59_Boots.geometry}
            material={materials.C59_Shoes}
            skeleton={nodes.C59_Boots.skeleton}
          />
          <skinnedMesh
            name="C87"
            geometry={nodes.C87.geometry}
            material={materials.C87_Mat}
            skeleton={nodes.C87.skeleton}
          />
          <skinnedMesh
            name="Hair"
            geometry={nodes.Hair.geometry}
            material={materials.Hair_M}
            skeleton={nodes.Hair.skeleton}
          />
          <group name="Head_2">
            <skinnedMesh
              name="Head_2_1"
              geometry={nodes.Head_2_1.geometry}
              material={materials['Head_new:lambert14']}
              skeleton={nodes.Head_2_1.skeleton}
            />
            <skinnedMesh
              name="Head_2_2"
              geometry={nodes.Head_2_2.geometry}
              material={materials['Head_new:eyebrow_mat']}
              skeleton={nodes.Head_2_2.skeleton}
            />
            <skinnedMesh
              name="Head_2_3"
              geometry={nodes.Head_2_3.geometry}
              material={materials['Head_new:eyelash_mat']}
              skeleton={nodes.Head_2_3.skeleton}
            />
            <skinnedMesh
              name="Head_2_4"
              geometry={nodes.Head_2_4.geometry}
              material={materials['Head_new:eyeleft_mat']}
              skeleton={nodes.Head_2_4.skeleton}
            />
            <skinnedMesh
              name="Head_2_5"
              geometry={nodes.Head_2_5.geometry}
              material={materials['Head_new:eyeright_mat']}
              skeleton={nodes.Head_2_5.skeleton}
            />
            <skinnedMesh
              name="Head_2_6"
              geometry={nodes.Head_2_6.geometry}
              material={materials['Head_new:cartige_mat']}
              skeleton={nodes.Head_2_6.skeleton}
            />
            <skinnedMesh
              name="Head_2_7"
              geometry={nodes.Head_2_7.geometry}
              material={materials['Head_new:saliva_mat']}
              skeleton={nodes.Head_2_7.skeleton}
            />
            <skinnedMesh
              name="Head_2_8"
              geometry={nodes.Head_2_8.geometry}
              material={materials['Head_new:teeth_mat']}
              skeleton={nodes.Head_2_8.skeleton}
            />
          </group>
          <skinnedMesh
            name="Timi_UE_BaseTimi_body"
            geometry={nodes.Timi_UE_BaseTimi_body.geometry}
            material={materials.blinn2}
            skeleton={nodes.Timi_UE_BaseTimi_body.skeleton}
          />
          <skinnedMesh
            name="Timi_UE_BaseTimi_head"
            geometry={nodes.Timi_UE_BaseTimi_head.geometry}
            material={materials['Timi_UE_Base:aiStandardSurface1']}
            skeleton={nodes.Timi_UE_BaseTimi_head.skeleton}
          />
        </group>
      </group>
    </group>
  )
}
export default TimiModel
useGLTF.preload('./models/Timi.gltf')
